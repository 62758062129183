<script lang="ts" setup>
import {
  ComponentBlockBlogTeaser,
  ComponentBlockBlogTeaserFragment,
} from "~~/types/graphql";

const props = defineProps<{
  BlogTeaserHeadline: ComponentBlockBlogTeaserFragment["BlogTeaserHeadline"];
  BlogTeaserBlogArticles: ComponentBlockBlogTeaserFragment["BlogTeaserBlogArticles"];
  BlogTeaserPage: ComponentBlockBlogTeaserFragment["BlogTeaserPage"];
}>();

const { d } = useI18n();

function link(url?: string) {
  if (url) {
    return `/${props.BlogTeaserPage?.data?.attributes?.Slug}/${url}`;
  }
  return `${props.BlogTeaserPage?.data?.attributes?.Slug}`;
}

/*
  Here are a lot of type errors, because apollo graphql doesn't infer
  types from nested fragments right now.
  --> ComponentBlockTeaserFragment has BlogLinkCardFragment
*/
</script>
<template>
  <layout-block
    class="blog-teaser"
    :title="props.BlogTeaserHeadline"
    :link="props.BlogTeaserPage?.data?.attributes?.Slug"
    has-cta
  >
    <div class="grid md:grid-cols-12 gap-5 mt-15">
      <ModuleLinkCard
        v-for="article in props.BlogTeaserBlogArticles?.data"
        :headline="article.attributes?.title"
        :subheadline="`${
          article.attributes?.category?.data?.attributes?.display_name ?? ''
        } ${d(article.attributes?.createdAt)}`"
        :image="article.attributes?.thumbnail?.data?.attributes"
        :url="link(article.attributes?.slug)"
        :type="'blog'"
        class="md:col-span-6"
      />
    </div>
  </layout-block>
</template>
